<template>
  <div
    :class="[
      'bc-icon-banner overflow-hidden p-0',
      { container: !fluid },
      { 'container-fluid': fluid },
    ]"
  >
    <div
      :style="{
        backgroundColor,
        color: textColor,
      }"
      class="bc-icon-banner__row position-relative overflow-hidden"
    >
      <slot></slot>

      <BCIcon
        class="bc-icon position-absolute"
        :style="{
          fill: iconColor,
          opacity: iconOpacity,
        }"
      />
    </div>
  </div>
</template>

<script>
import BCIcon from '~/assets/icons/bc-icon.svg'

export default {
  components: {
    BCIcon,
  },
  props: {
    backgroundColor: {
      type: String,
      default: '#eef4f2',
    },
    textColor: {
      type: String,
      default: '#000',
    },
    iconColor: {
      type: String,
      default: '#dde6e3',
    },
    iconOpacity: {
      type: Number,
      default: 1,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.bc-icon-banner__row {
  padding: 56px 0 64px;
  text-align: center;
  z-index: 1;
}

:slotted(h1),
:slotted(h2),
:slotted(h3),
:slotted(h4) {
  color: inherit;
}

.bc-icon {
  z-index: -1;
  width: 450vw;
  transform: translate(-48.2%, -40.5%) rotate(156.62deg);
}

@include media-breakpoint-up(lg) {
  .bc-icon-banner__row {
    padding: 96px 0;
  }

  .bc-icon {
    width: 156.5vw;
    transform: translate(-50%, -37%) rotate(156.62deg);
  }
}
</style>
